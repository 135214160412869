import { Signal } from '@angular/core';

/**
 * Transforms a tuple or array of string literals into an object type where each key corresponds
 * to an item from the array and each value is of a specified type `V`.
 *
 * @template T - A tuple or array of string literals
 * @template V - The type of the values in the resulting object (default is `string`)
 *
 * This type is useful when you need to create an object whose keys should be limited to a specific set of strings,
 * and where each value in the object should be of a specific type.
 *
 * @example
 *
 * type Pets = ['cat', 'dog', 'bird'];
 * type PetDetails = StringArrayKeysToObjectType<Pets, { name: string, age: number }>;
 *
 * const myPets: PetDetails = {
 *   cat: { name: 'Milo', age: 2 },
 *   dog: { name: 'Rex', age: 5 }
 * };
 *
 * @returns An object type where each key is a string from the `T` array, and each value is of type `V`.
 */
export type StringArrayKeysToObjectType<T extends readonly string[], V = string> = {
  [P in T[number]]?: V;
};

/**
 * Represents the value type of a property in an object.
 *
 * @template T - An object type
 * @template P - A key of object `T` (default is any key of `T`)
 *
 * The `ValueOf` type allows you to get the type of a specific property in `T`,
 * or if the `P` parameter is omitted, a union type of the values of all properties in `T`.
 *
 * @example
 *
 * const person = { name: 'John', age: 25 };
 *
 * type AgeType = ValueOf<typeof person, 'age'>;  // AgeType is of type number
 * type AnyType = ValueOf<typeof person>;  // AnyType is of type number | string
 *
 * @returns The type of property `P` in `T`, or if `P` is not provided,
 * a union type of the values of all properties in `T`.
 */
export type ValueOf<T, P extends keyof T = keyof T> = T[P];

export type ValuesOf<T extends object> = T[keyof T];

export type KeyAsString<T extends object> = Extract<keyof T, string>;

export type SignalType<T> = T extends Signal<infer U> ? U : never;
