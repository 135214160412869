import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-full-height-container',
  template: `
    <mat-toolbar class="transparent">
      <button mat-icon-button routerLink="..">
        <mat-icon> arrow_back </mat-icon>
      </button>
      <div class="mat-headline-4">
        {{ title }}
      </div>
      <div class="spacer"></div>
      <ng-content select="[container-actions]"></ng-content>
    </mat-toolbar>
    <mat-card class="full-height" appearance="outlined">
      <mat-card-content>
        <ng-content></ng-content>
      </mat-card-content>
    </mat-card>
  `,
  styles: [
    `
      :host {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    `,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatToolbar, MatIconButton, MatIcon, MatCard, MatCardContent, RouterLink],
})
export class FullHeightContainerComponent {
  @Input({
    required: true,
  })
  title: string;
}
