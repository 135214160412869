<button (click)="openDialog()" mat-icon-button>
  <mat-icon class="user-guide-icon" [style.transform]="'scale(' + iconScale + ')'">info</mat-icon>
</button>
<ng-template #userGuideDialog>
  <div class="Mid_Blue mat-headline-6" mat-dialog-title>
    Help
    <button (click)="closeDialog()" mat-icon-button tabindex="-1">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <span class="mat-headline-6" mat-dialog-title>{{ infoTitle }}</span>

  <mat-dialog-content class="mat-typography">
    <mat-accordion *ngIf="!informationLists">
      <mat-expansion-panel
        class="mb-2 mat-elevation-z1"
        #mep1="matExpansionPanel"
        [expanded]="entityName === 'rcsa'"
        (closed)="panelOpenState = false"
        (opened)="panelOpenState = true"
      >
        <mat-expansion-panel-header class="disable_ripple">
          <mat-panel-title class="Mid_Blue mat-body-1"> RCSA </mat-panel-title>
          <mat-panel-description class="mat-body-1" *ngIf="!mep1.expanded"> Review and sign off </mat-panel-description>
        </mat-expansion-panel-header>
        <h2 class="mat-headline-5">
          The objective of the RCSA, is to provide transparency around the current risk and control environment, ongoing and recently
          completed issues and actions as well as operational events that have happened.
        </h2>
        <h3 class="mat-headline-6 swiss-re-container small">Summary</h3>
        <ul>
          <li class="mat-subtitle-1">
            The RCSA is the primary instrument used by the RCSA Owners to periodically (re-) assess control effectiveness against identified
            risks.
          </li>
          <li class="mat-subtitle-1">As a rule, RCSAs are allocated to department heads (Managing Director level).</li>
          <li class="mat-subtitle-1">
            The RCSA Owner may delegate the operational task of the control effectiveness assessments to control assessors within the team,
            but the RCSA owner remains responsible for the complete and accurate content overall.
          </li>
          <li class="mat-subtitle-1">A quarterly sign off is mandatory to confirm that the RCSA is complete and up-to-date</li>
          <li class="mat-subtitle-1">
            With the sign off, the RCSA owner confirms that
            <ul>
              <li class="mat-subtitle-1">All material risks have been identified and controls have been assessed;</li>
              <li class="mat-subtitle-1">Control deficiencies are addressed with appropriate issues and actions; and</li>
              <li class="mat-subtitle-1">All known operational events are recorded.</li>
            </ul>
          </li>
        </ul>
        <h3 class="mat-subtitle-1">
          For details, please refer to the
          <a
            href="https://swissre.sharepoint.com/sites/policymanagement/PMTPublishedDocuments/5/T000650.pdf"
            rel="noopener noreferrer"
            target="_blank"
            >L4-RCSA Guidance document</a
          >
        </h3>
      </mat-expansion-panel>

      <mat-expansion-panel
        class="mb-2 mat-elevation-z1"
        #mep2="matExpansionPanel"
        [expanded]="entityName === 'dashboard'"
        (closed)="panelOpenState = false"
        (opened)="panelOpenState = true"
      >
        <mat-expansion-panel-header class="disable_ripple">
          <mat-panel-title class="Mid_Blue mat-body-1"> Dashboard </mat-panel-title>
          <mat-panel-description class="mat-body-1" *ngIf="!mep2.expanded">
            Discover exceptions and risk mitigation relevant observations
          </mat-panel-description>
        </mat-expansion-panel-header>
        <h2 class="mat-headline-5">
          The RCSA - Dashboard provides an overview over a specific RCSA showing exceptions requiring action and highlighting risk
          mitigation relevant observations.
        </h2>

        <h3 class="mat-headline-6 swiss-re-container small">Exceptions requiring actions (upper row in the dashboard)</h3>
        <ul>
          <li class="mat-subtitle-1">Risks with not effective controls and no open issues to resolve</li>
          <li class="mat-subtitle-1">Events that have been reported but not submitted within 30 days</li>
          <li class="mat-subtitle-1">Overdue issues and issues that have been reported but not submitted within 30 days</li>
        </ul>

        <h3 class="mat-headline-6">Risk relevant observations (lower row in the dashboard)</h3>
        <ul>
          <li class="mat-subtitle-1">Number of risks with not effective, assessed or missing controls</li>
          <li class="mat-subtitle-1">Number of not effective controls</li>
          <li class="mat-subtitle-1">Events reported in the current reporting period</li>
          <li class="mat-subtitle-1">Issues due in the current reporting period</li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel
        class="mb-2 mat-elevation-z1"
        #mep3="matExpansionPanel"
        [expanded]="entityName === 'risks'"
        (closed)="panelOpenState = false"
        (opened)="panelOpenState = true"
      >
        <mat-expansion-panel-header class="disable_ripple">
          <mat-panel-title class="Mid_Blue mat-body-1"> Risks </mat-panel-title>
          <mat-panel-description class="mat-body-1" *ngIf="!mep3.expanded">
            Get an overview of the risks and how they are mitigated
          </mat-panel-description>
        </mat-expansion-panel-header>
        <h2 class="mat-headline-5">
          The RCSA - Risk screen shows all risks within a selected RCSA, with all mitigating controls, open issues and open events.
        </h2>
        <h3 class="mat-headline-6 swiss-re-container small">Risk mitigation overview</h3>
        <ul>
          <li class="mat-subtitle-1">All risks within the selected RCSA</li>
          <li class="mat-subtitle-1">Issues and events directly associated to each risk</li>
          <li class="mat-subtitle-1">Controls within the responsibility of the RCSA owner</li>
          <li class="mat-subtitle-1">Controls within the responsibility of other RCSA owners mitigating risks of the selected RCSA</li>
          <li class="mat-subtitle-1">Issues and events associated to each control</li>
        </ul>
        <h3 class="mat-headline-6">Highlighted Observations</h3>
        <ul>
          <li class="mat-subtitle-1">Risk for which there is no control</li>
          <li class="mat-subtitle-1">Risks with not effective controls and no open issue to resolve</li>
          <li class="mat-subtitle-1">Controls assessed as not effective</li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel
        class="mb-2 mat-elevation-z1"
        #mep4="matExpansionPanel"
        [expanded]="entityName === 'controls'"
        (closed)="panelOpenState = false"
        (opened)="panelOpenState = true"
      >
        <mat-expansion-panel-header class="disable_ripple">
          <mat-panel-title class="Mid_Blue mat-body-1"> Controls </mat-panel-title>
          <mat-panel-description class="mat-body-1" *ngIf="!mep4.expanded"> Review controls </mat-panel-description>
        </mat-expansion-panel-header>
        <h2 class="mat-headline-5">
          The RCSA - Controls screen contains various sub screens of which the Control assessment and the Controls performed in other
          departments are the most relevant ones for the quarterly RCSA.
        </h2>
        <h3 class="mat-headline-6 swiss-re-container small">Control assessment</h3>
        <ul>
          <li class="mat-subtitle-1">
            All controls within the responsibility of the RCSA owner, which have to be reviewed and updated by the assigned control
            assessors once a quarter.
          </li>
        </ul>
        <h3 class="mat-headline-6">Controls performed in other departments</h3>
        <ul>
          <li class="mat-subtitle-1">
            All controls mitigating risks within the own RCSA but are performed somewhere. The RCSA owner should be aware of the performance
            of these controls to ensure that own risks are mitigated appropriately.
          </li>
        </ul>
        <h3 class="mat-headline-6">Control details</h3>
        <ul>
          <li class="mat-subtitle-1">Highlighting selected attributes for easier review</li>
        </ul>
        <h3 class="mat-headline-6">Comparison with the Global Control Catalogue (GCC)</h3>
        <ul>
          <li class="mat-subtitle-1">List of all attributes that are defined in the GCC but can be updated in the RCSA.</li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel
        class="mb-2 mat-elevation-z1"
        #mep5="matExpansionPanel"
        [expanded]="entityName === 'control-assessments'"
        (closed)="panelOpenState = false"
        (opened)="panelOpenState = true"
      >
        <mat-expansion-panel-header class="disable_ripple">
          <mat-panel-title class="Mid_Blue mat-body-1"> Control Assessment </mat-panel-title>
          <mat-panel-description class="mat-body-1" *ngIf="!mep5.expanded"> Perform a control assessment </mat-panel-description>
        </mat-expansion-panel-header>
        <h2 class="mat-headline-5">Control Assessors perform the control assessment on this screen.</h2>
        <br />
        <h2 class="mat-headline-5">
          OneORM supports multiple people working on a control assessment allowing to save individual edits without completing the
          assessment (UPDATE button). Each assessment is completed with signing it off (SIGN OFF button). If you are not sure how to assess
          a control, contact your assigned Risk Manager for support.
        </h2>
        <br />
        <h3 class="mat-headline-6 swiss-re-container small">Frequency</h3>
        <ul>
          <li class="mat-subtitle-1">
            Key control assessments must be updated and signed off quarterly as prerequisite for the quarterly RCSA sign off.
          </li>
          <li class="mat-subtitle-1">Non-key control assessments must be updated and signed off at least annually.</li>
          <li class="mat-subtitle-1">
            Controls can be re-assessed at any point in time. They should be re-assessed whenever there is a change in the control
            effectiveness or change in control environment. E.g., a new issue is raised on this control, or linked issues have been
            completed.
          </li>
        </ul>
        <h3 class="mat-headline-6 swiss-re-container small">Assessment Rating</h3>
        <ul>
          <li class="mat-subtitle-1">
            <span class="sr-positive-text no-wrap mat-subtitle-1">"Effective"</span> - The control is
            <u>designed to mitigate the risk</u> AND <u>performed in accordance with its design.</u>
          </li>
          <li class="mat-subtitle-1">
            <span class="sr-negative-text no-wrap mat-subtitle-1">"Not Effective" </span> - The control is not designed effectively to
            mitigate the risk, not operating effectively, OR is not implemented. An issue must be raised within 30 days.
          </li>
          <li class="mat-subtitle-1">
            <span class="notApplicable mat-subtitle-1">"Not Applicable" </span>
            - The control is temporarily not applicable, e.g., project controls depending on project phase OR the control has become
            obsolete and needs to be removed. Please contact your assigned Risk Manager.
          </li>
          <li class="mat-subtitle-1">
            <span class="sr-highlight-text no-wrap mat-subtitle-1">"Not Assessed" </span> - "Not assessed" is the default value for new
            controls and should not be used in assessments.
          </li>
        </ul>
        <h3 class="mat-headline-6">Assessment Commentary</h3>
        <ul>
          <li class="mat-subtitle-1">
            Explain the rationale in support of the control effectiveness rating considering design and operating effectiveness. It should
            indicate the activities performed to assess whether control was effective or not.
          </li>
          <li class="mat-subtitle-1">
            The assessment comment is <u>mandatory</u> for all key control assessments. For non-key controls, the assessment comment is only
            mandatory if assessed as not effective.
          </li>
          <li class="mat-subtitle-1">Assessment comments must be updated in subsequent assessments explaining any changes.</li>
        </ul>
        <h3 class="mat-headline-6">Evidence</h3>
        <ul>
          <li class="mat-subtitle-1">Describe the specific evidence relevant for your assessment of the control performance.</li>
          <li class="mat-subtitle-1">Indicate where the evidence is stored.​</li>
          <li class="mat-subtitle-1">Evidence must be updated in subsequent assessments if there are any changes.</li>
        </ul>
        <h3 class="mat-headline-6">Rating History</h3>
        <ul>
          <li class="mat-subtitle-1">Shows the control assessment rating of the last 4 reporting periods</li>
        </ul>
        <h3 class="mat-headline-6">Update History</h3>
        <ul>
          <li class="mat-subtitle-1">Indicates when and by whom the last assessment has been performed.</li>
          <li class="mat-subtitle-1 sr-negative-text">
            Important:
            <ul>
              <li class="mat-subtitle-1 sr-negative-text">Each assessment must be completed with a sign off (SIGN OFF button)</li>
              <li class="mat-subtitle-1 sr-negative-text">
                Multiple edits by different people are allowed to support teamwork and peer reviews (UPDATE button)
              </li>
            </ul>
          </li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel
        class="mb-2 mat-elevation-z1"
        #mep6="matExpansionPanel"
        [expanded]="entityName === 'events'"
        (closed)="panelOpenState = false"
        (opened)="panelOpenState = true"
      >
        <mat-expansion-panel-header class="disable_ripple">
          <mat-panel-title class="Mid_Blue mat-body-1"> Events </mat-panel-title>
          <mat-panel-description class="mat-body-1" *ngIf="!mep6.expanded"> Review events </mat-panel-description>
        </mat-expansion-panel-header>
        <h2 class="mat-headline-5">The RCSA – Event screen shows all events belonging to the selected RCSA.</h2>
        <h3 class="mat-headline-6 swiss-re-container small">Summary</h3>
        <ul>
          <li class="mat-subtitle-1">
            The objective of operational event reporting is to enhance transparency of operational mishaps, errors or omissions, to learn
            from lessons and to prevent operational events from re-occurring. Recorded lessons learned form a knowledge base for risk takers
            and controllers.
          </li>
          <li class="mat-subtitle-1">
            All Swiss Re employees must report operational events when discovered if any of the following reporting thresholds or criteria
            applies:
            <ul>
              <li class="mat-subtitle-1">Cash-out exceeding a threshold of USD 10k;</li>
              <li class="mat-subtitle-1">Fraudulent activities (only when case is closed);</li>
              <li class="mat-subtitle-1">(Potential) Legal and regulatory consequences;</li>
              <li class="mat-subtitle-1">(Potential) Reputational damage for the company;</li>
              <li class="mat-subtitle-1">Financial misstatements such as Group US GAAP, Statutory GAAP or EVM; or</li>
              <li class="mat-subtitle-1">Lessons learned for the benefit of more than one functional area.</li>
            </ul>
          </li>
        </ul>
        <h3 class="mat-subtitle-1">
          For details, please refer to the
          <a
            href="https://swissre.sharepoint.com/sites/policymanagement/PMTPublishedDocuments/5/T000647.pdf"
            rel="noopener noreferrer"
            target="_blank"
            >L4-Op Event Reporting Guidance document.</a
          >
        </h3>
      </mat-expansion-panel>

      <mat-expansion-panel
        class="mb-2 mat-elevation-z1"
        #mep7="matExpansionPanel"
        [expanded]="entityName === 'issues'"
        (closed)="panelOpenState = false"
        (opened)="panelOpenState = true"
      >
        <mat-expansion-panel-header class="disable_ripple">
          <mat-panel-title class="Mid_Blue mat-body-1"> Issues </mat-panel-title>
          <mat-panel-description class="mat-body-1" *ngIf="!mep7.expanded"> Review issues </mat-panel-description>
        </mat-expansion-panel-header>
        <h2 class="mat-headline-5">The RCSA – Issues screen shows all issues belonging to the selected RCSA.</h2>
        <h3 class="mat-headline-6 swiss-re-container small">Summary</h3>
        <ul>
          <li class="mat-subtitle-1">
            The objective of issue management is to focus management’s attention on areas that require improvements within their risk and
            control environment and take action to mitigate operational risks.
          </li>
          <li class="mat-subtitle-1">
            An issue describes a problem where a process or a control is nonexistent, is not working as designed or where there is an
            opportunity to strengthen an existing control. Corrective action plans are required to fix the issue within a timely manner in
            order to avoid a risk materializing/move outside the tolerance limits for Swiss Re.
          </li>
          <li class="mat-subtitle-1">
            Identified control deficiencies, missing controls and risk gaps requiring improvement as well as risk reviews, audit activities,
            and reported operational events are reasons for raising issues.
          </li>
        </ul>
        <h3 class="mat-subtitle-1">
          For details, please refer to the
          <a
            href="https://swissre.sharepoint.com/sites/policymanagement/PMTPublishedDocuments/5/T000646.pdf"
            rel="noopener noreferrer"
            target="_blank"
            >L4-Issue Management Guidance document.</a
          >
        </h3>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion class="fix-radio-position" *ngIf="informationLists" hideToggle="true">
      <mat-expansion-panel *ngFor="let item of informationLists; let i = index" [expanded]="infoKey === item.infoKey">
        <mat-expansion-panel-header>
          <mat-panel-description class="d-flex" style="align-items: center">
            {{ item.title }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div [innerHTML]="item.information"></div>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="borderless" (click)="closeDialog()" color="primary" mat-button mat-stroked-button>
      <span class="text-bolder">CLOSE</span>
    </button>
  </mat-dialog-actions>
</ng-template>
