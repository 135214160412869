import { PROTOCOL_MARK, WWW_MARK } from '@shared/constants';

const isValidUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

export const isValidExternalUrl = (url: unknown, currentUrl: string): boolean => {
  if (typeof url !== 'string') {
    return false;
  }

  let combinedUrl = url;

  if (combinedUrl.startsWith(WWW_MARK)) {
    combinedUrl = PROTOCOL_MARK + url;
  }

  if (!isValidUrl(combinedUrl)) {
    return false;
  }

  return new URL(combinedUrl).origin !== new URL(currentUrl).origin;
};
